import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import SupportFeedbackComposition from 'web/src/modules/support/submodules/feedback/pages/SupportFeedbackRoutePage/components/Composition/SupportFeedbackComposition.vue';
import { LazyDefaultModal as DefaultModal } from 'web/src/components/Modal/DefaultModal';
import { useSelfExclusionFeedbackModal } from 'web/src/modules/responsible-gambling/components/SelfExclusionFeedbackModal/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SelfExclusionFeedbackModal',
    emits: [
        "success",
        "cancel"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { modalSelector, modalProperties, feedBackProperties, onSubmit, closeModal } = useSelfExclusionFeedbackModal(emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                selector: _unref(modalSelector)
            }, {
                default: _withCtx(()=>[
                        _createVNode(_unref(DefaultModal), _mergeProps({
                            class: _ctx.$style['sef-modal']
                        }, _unref(modalProperties), {
                            onClose: _unref(closeModal)
                        }), {
                            "inner-content": _withCtx(()=>[
                                    _createElementVNode("div", null, [
                                        _createElementVNode("p", {
                                            class: _normalizeClass(_ctx.$style['sef-modal__title'])
                                        }, _toDisplayString(_ctx.$t("WEB2_SELF_EXCLUSION_FEEDBACK_DESCRIPTION")), 3),
                                        _createVNode(SupportFeedbackComposition, _mergeProps(_unref(feedBackProperties), {
                                            onSubmit: _unref(onSubmit)
                                        }), null, 16, [
                                            "onSubmit"
                                        ])
                                    ])
                                ]),
                            _: 1
                        }, 16, [
                            "class",
                            "onClose"
                        ])
                    ]),
                _: 1
            }, 8, [
                "selector"
            ])), [
                [
                    _directive_auto_id,
                    'SelfExclusionFeedbackModal'
                ]
            ]);
        };
    }
});
