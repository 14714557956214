import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 2
};
const _hoisted_2 = {
    key: 1
};
import { onBeforeMount } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import useResponsibleGamblingSelfExclusionRoutePage from 'web/src/modules/responsible-gambling/pages/ResponsibleGamblingSelfExclusionRoutePage/composables/useResponsibleGamblingSelfExclusionRoutePage';
import { VLoaderDelayed } from 'web/src/components/Loader';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { VForm } from 'web/src/components/Form';
import SelfExclusionFeedbackModal from 'web/src/modules/responsible-gambling/components/SelfExclusionFeedbackModal/SelfExclusionFeedbackModal.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingSelfExclusionRoutePage',
    setup (__props) {
        const { showFeedbackModal, handleCloseFeedback, handleSuccessFeedback, selfExclusion, handleSupportClick, formattedFrom, formattedUntil, buttonPropertiesSupport, schema, uiSchema, isLoading, onChange, buttonProperties, showConfirmModal, beforeMount } = useResponsibleGamblingSelfExclusionRoutePage();
        onBeforeMount(beforeMount);
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    _ctx.$style['responsible-gambling'],
                    _ctx.$style['self-exclusion']
                ])
            }, [
                _unref(showFeedbackModal) ? (_openBlock(), _createBlock(SelfExclusionFeedbackModal, {
                    key: 0,
                    onCancel: _unref(handleCloseFeedback),
                    onSuccess: _unref(handleSuccessFeedback)
                }, null, 8, [
                    "onCancel",
                    "onSuccess"
                ])) : _createCommentVNode("", true),
                _unref(selfExclusion) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _unref(selfExclusion).isSelfExcluded ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        (_openBlock(), _createBlock(VHintBlock, {
                            key: 1
                        }, {
                            label: _withCtx(()=>[
                                    _createVNode(_unref(TextSlotPlaceholder), {
                                        text: _ctx.$t('WEB2_SELF_EXCLUSION_MESSAGE'),
                                        class: _normalizeClass(_ctx.$style['responsible-gambling__hint-block'])
                                    }, {
                                        from: _withCtx(()=>[
                                                _createElementVNode("span", {
                                                    class: _normalizeClass(_ctx.$style['responsible-gambling__hint-block-time'])
                                                }, _toDisplayString(_unref(formattedFrom)), 3)
                                            ]),
                                        until: _withCtx(()=>[
                                                _createElementVNode("span", {
                                                    class: _normalizeClass(_ctx.$style['responsible-gambling__hint-block-time'])
                                                }, _toDisplayString(_unref(formattedUntil)), 3)
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "text",
                                        "class"
                                    ])
                                ]),
                            footer: _withCtx(()=>[
                                    _createVNode(_component_router_link, {
                                        to: _ctx.$to(_unref(RouteName).SUPPORT),
                                        class: _normalizeClass(_ctx.$style['responsible-gambling__support-button'])
                                    }, {
                                        default: _withCtx(()=>[
                                                _createVNode(VButton, _normalizeProps(_guardReactiveProps(_unref(buttonPropertiesSupport))), null, 16)
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "to",
                                        "class"
                                    ])
                                ]),
                            _: 1
                        }))
                    ], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (_openBlock(), _createBlock(VHintBlock, {
                            key: 1
                        }, {
                            title: _withCtx(()=>[
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_MODALTITLE_ATTENTION')) + "!", 1)
                                ]),
                            default: _withCtx(()=>[
                                    _createVNode(_unref(TextSlotPlaceholder), {
                                        text: _ctx.$t('WEB2_SELF_EXCLUSION_PERIOD_DESCR'),
                                        class: _normalizeClass(_ctx.$style['responsible-gambling__hint-block'])
                                    }, null, 8, [
                                        "text",
                                        "class"
                                    ])
                                ]),
                            _: 1
                        })),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['responsible-gambling__select-period'])
                        }, [
                            _unref(schema) ? (_openBlock(), _createBlock(_unref(VForm), {
                                key: 0,
                                ref: "form",
                                schema: _unref(schema),
                                "ui-schema": _unref(uiSchema),
                                "is-pending": _unref(isLoading),
                                onChange: _unref(onChange)
                            }, null, 8, [
                                "schema",
                                "ui-schema",
                                "is-pending",
                                "onChange"
                            ])) : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['responsible-gambling__content-block'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['responsible-gambling__content-block--button'])
                            }, [
                                _createVNode(VButton, _mergeProps(_unref(buttonProperties), {
                                    onClick: _unref(showConfirmModal)
                                }), null, 16, [
                                    "onClick"
                                ])
                            ], 2)
                        ], 2)
                    ]))
                ])) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 1
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingSelfExclusionRoutePage'
                ]
            ]);
        };
    }
});
