import { defineComponent as _defineComponent } from 'vue';
import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { Tag } from '@leon-hub/tags';
import { ButtonKind } from '@leon-hub/module-buttons';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import { useI18n } from 'web/src/modules/i18n/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingPartialRestrictionRoutePage',
    setup (__props) {
        /**
 * TODO: Link button to support page
 */ const { $translate } = useI18n();
        const router = useRouter();
        const buttonProperties = computed(()=>({
                label: $translate('WEB2_HELP').value,
                kind: ButtonKind.SECONDARY,
                rounded: true,
                fullWidth: true,
                tag: Tag.A,
                href: router.resolve({
                    name: RouteName.SUPPORT
                }).href
            }));
        function handleSupportClick() {
            router.push({
                name: RouteName.SUPPORT
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['partial-restriction'])
            }, [
                (_openBlock(), _createBlock(VHintBlock, {
                    key: 1
                }, {
                    label: _withCtx(()=>[
                            _createVNode(_unref(TextSlotPlaceholder), {
                                text: _ctx.$t('WEB2_PARTIAL_RESTRICTION')
                            }, {
                                support: _withCtx(()=>[
                                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_PARTIAL_RESTRICTION_LINK')), 1)
                                    ]),
                                _: 1
                            }, 8, [
                                "text"
                            ])
                        ]),
                    footer: _withCtx(()=>[
                            _createVNode(VButton, _mergeProps({
                                class: _ctx.$style['responsible-gambling__support-button']
                            }, buttonProperties.value, {
                                onClick: _withModifiers(handleSupportClick, [
                                    "prevent"
                                ])
                            }), null, 16, [
                                "class"
                            ])
                        ]),
                    _: 1
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingPartialRestrictionRoutePage'
                ]
            ]);
        };
    }
});
