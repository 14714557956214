import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { PinCodeRequiredExceptionCode, validationSchemaCustomerLimits } from '@leon-hub/api-sdk/src/sdk/sdk';
import RouteName from '@leon-hub/routing-config-names';
import { FormControlType } from 'web/src/components/Form/enums';
import { useFormsStore } from 'web/src/modules/forms/store';
import { useResponsibleGamblingStore } from 'web/src/modules/responsible-gambling/store';
import { ResponsibleGamblingLimitsMapper } from 'web/src/modules/responsible-gambling/pages/types';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useResponsibleGamblingBettingLimitsRoutePage() {
    const router = useRouter();
    const responsibleGamblingStore = useResponsibleGamblingStore();
    const formsStore = useFormsStore();
    const { $translate } = useI18n();
    const betLimits = toRef(responsibleGamblingStore, 'betLimits');
    const betLimitsLoading = toRef(responsibleGamblingStore, 'betLimitsLoading');
    const depositLimitsData = toRef(responsibleGamblingStore, 'depositLimitsData');
    const customerLimitsSchema = computed(()=>formsStore.customerLimitsSchema ?? {
            properties: {}
        });
    const isLoading = computed(()=>!(betLimits.value && customerLimitsSchema.value));
    const dayUiSchema = computed(()=>({
            validatorErrorPatterns: {
                common: {
                    pattern: $translate('WEB2_DAILY_LIMIT_ERROR_DESCR').value
                }
            },
            order: [
                'limit'
            ],
            submitButton: null,
            fields: {
                type: {
                    hidden: true,
                    default: 'DAILY'
                },
                limit: {
                    title: $translate('WEB2_DAILY_LIMIT').value,
                    default: '',
                    options: {
                        placeholder: $translate('WEB2_ENTER_AMOUNT').value
                    },
                    widget: FormControlType.CurrencyInput
                }
            }
        }));
    const weekUiSchema = computed(()=>({
            validatorErrorPatterns: {
                common: {
                    pattern: $translate('WEB2_WEEKLY_LIMIT_ERROR_DESCR').value
                }
            },
            submitButton: null,
            order: [
                'limit'
            ],
            fields: {
                type: {
                    hidden: true,
                    default: 'WEEKLY'
                },
                limit: {
                    title: $translate('WEB2_WEEKLY_LIMIT').value,
                    default: '',
                    options: {
                        placeholder: $translate('WEB2_ENTER_AMOUNT').value
                    },
                    widget: FormControlType.CurrencyInput
                }
            }
        }));
    const monthUiSchema = computed(()=>({
            validatorErrorPatterns: {
                common: {
                    pattern: $translate('WEB2_MONTHLY_LIMIT_ERROR_DESCR').value
                }
            },
            submitButton: null,
            order: [
                'limit'
            ],
            fields: {
                type: {
                    hidden: true,
                    default: 'MONTHLY'
                },
                limit: {
                    title: $translate('WEB2_MONTHLY_LIMIT').value,
                    default: '',
                    options: {
                        placeholder: $translate('WEB2_ENTER_AMOUNT').value
                    },
                    widget: FormControlType.CurrencyInput
                }
            }
        }));
    const list = computed(()=>{
        const result = {};
        if (betLimits.value?.dailyLimit !== void 0) result.dailyLimit = betLimits.value.dailyLimit;
        if (betLimits.value?.weeklyLimit !== void 0) result.weeklyLimit = betLimits.value.weeklyLimit;
        if (betLimits.value?.monthlyLimit !== void 0) result.monthlyLimit = betLimits.value.monthlyLimit;
        return result;
    });
    function uiSchema(type) {
        if ('dailyLimit' === type) return dayUiSchema.value;
        if ('weeklyLimit' === type) return weekUiSchema.value;
        return monthUiSchema.value;
    }
    function handleLimit(limitPeriod, $event) {
        depositLimitsData.value = {
            limitPeriod,
            $event
        };
        return responsibleGamblingStore.setBetLimitsAction({
            ...$event,
            limitPeriod
        }).then(()=>{
            depositLimitsData.value = null;
        }).catch((error)=>{
            if (error.message === PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED) {
                responsibleGamblingStore.setDepositLimitsData(depositLimitsData.value);
                router.replace({
                    name: RouteName.PIN_CODE_PROFILE
                });
            }
        });
    }
    function getLimitUpdatableAt(limitName) {
        const updatableAtName = ResponsibleGamblingLimitsMapper(limitName);
        const { betLimitsUpdatableAt } = responsibleGamblingStore;
        if (updatableAtName && betLimitsUpdatableAt) return betLimitsUpdatableAt[updatableAtName];
        return 0;
    }
    async function beforeMounted() {
        await Promise.all([
            responsibleGamblingStore.loadBetLimits(),
            formsStore.fetchSchema(validationSchemaCustomerLimits)
        ]);
        if (depositLimitsData.value) {
            await handleLimit(depositLimitsData.value.limitPeriod, depositLimitsData.value.$event);
            responsibleGamblingStore.setDepositLimitsData(null);
        }
    }
    return {
        isLoading,
        list,
        customerLimitsSchema,
        uiSchema,
        betLimitsLoading,
        getLimitUpdatableAt,
        handleLimit,
        beforeMounted
    };
}
