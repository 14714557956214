import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "feedback",
    "data-test-id": "feedback-wrapper"
};
import { VLoader } from '@components/loader';
import { VForm } from 'web/src/components/Form';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SupportFeedbackComposition',
    props: {
        loaded: {
            type: Boolean
        },
        isPending: {
            type: Boolean
        },
        schema: {},
        uiSchema: {},
        hintLabel: {}
    },
    emits: [
        "submit"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _ctx.loaded ? (_openBlock(), _createBlock(_unref(VForm), {
                    key: 0,
                    schema: _ctx.schema,
                    "ui-schema": _ctx.uiSchema,
                    "is-pending": _ctx.isPending,
                    onSubmit: _cache[0] || (_cache[0] = ($event)=>emit('submit', $event))
                }, null, 8, [
                    "schema",
                    "ui-schema",
                    "is-pending"
                ])) : (_openBlock(), _createBlock(_unref(VLoader), {
                    key: 1
                })),
                _createVNode(VHintBlock, {
                    label: _ctx.hintLabel,
                    class: "feedback__hint"
                }, null, 8, [
                    "label"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'SupportFeedbackComposition'
                ]
            ]);
        };
    }
});
