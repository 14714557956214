import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { onBeforeMount } from 'vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import ResponsibleGamblingForm from 'web/src/modules/responsible-gambling/components/ResponsibleGamblingForm/ResponsibleGamblingForm.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import { ResponsibleGamblingLimitsType } from 'web/src/modules/responsible-gambling/pages/types';
import useResponsibleGamblingDepositLimitsRoutePage from 'web/src/modules/responsible-gambling/pages/ResponsibleGamblingDepositLimitsRoutePage/composables/useResponsibleGamblingDepositLimitsRoutePage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingDepositLimitsRoutePage',
    setup (__props) {
        const { isLoading, list, customerLimitsSchema, uiSchema, depositLimitsLoading, getLimitUpdatableAt, handleLimit, beforeMounted } = useResponsibleGamblingDepositLimitsRoutePage();
        onBeforeMount(()=>{
            beforeMounted();
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['responsible-gambling'])
            }, [
                (_openBlock(), _createBlock(VHintBlock, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['responsible-gambling__hint-block'])
                }, {
                    title: _withCtx(()=>[
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_IMPORTANT_TITLE')), 1)
                        ]),
                    default: _withCtx(()=>[
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('WEB2_RG_LIMIT_DESC')), 1)
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ])),
                _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling__content-block'])
                }, [
                    _unref(isLoading) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                        key: 0
                    })) : _createCommentVNode("", true),
                    _unref(isLoading) ? _createCommentVNode("", true) : (_openBlock(true), _createElementBlock(_Fragment, {
                        key: 1
                    }, _renderList(_unref(list), (limit, name, index)=>(_openBlock(), _createBlock(ResponsibleGamblingForm, {
                            key: index + name + limit,
                            schema: _unref(customerLimitsSchema),
                            "ui-schema": _unref(uiSchema)(name),
                            limit: limit,
                            "limit-name": name,
                            "form-type": _unref(ResponsibleGamblingLimitsType).DEPOSIT,
                            loading: _unref(depositLimitsLoading)[name],
                            timestamp: _unref(getLimitUpdatableAt)(name),
                            onFormOutput: ($event)=>_unref(handleLimit)(name, $event),
                            onDeleteLimit: ($event)=>_unref(handleLimit)(name, $event)
                        }, null, 8, [
                            "schema",
                            "ui-schema",
                            "limit",
                            "limit-name",
                            "form-type",
                            "loading",
                            "timestamp",
                            "onFormOutput",
                            "onDeleteLimit"
                        ]))), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingDepositLimitsRoutePage'
                ]
            ]);
        };
    }
});
