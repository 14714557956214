import { computed, ref } from 'vue';
import { FeedbackChannel } from '@leon-hub/api-sdk';
import { FormControlType, FormState } from 'web/src/components/Form/enums';
import { useSupportFeedbackStore } from 'web/src/modules/support/submodules/feedback/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ModalSelector } from 'web/src/modules/core/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import feedbackSchema from 'web/src/modules/responsible-gambling/pages/schemas/feedbackSchema';
export default function useSelfExclusionFeedbackModal(emit) {
    const { $translate } = useI18n();
    const { sendUserResponse } = useSupportFeedbackStore();
    const modalSelector = ModalSelector.BODY;
    const formState = ref(FormState.INITIAL);
    const isPending = computed(()=>formState.value === FormState.PENDING);
    const uiSchema = computed(()=>{
        let buttonLabel = $translate('WEB2_SELF_EXCLUSION_FEEDBACK_CONFIRM').value;
        switch(formState.value){
            case FormState.PENDING:
                buttonLabel = $translate('WEB2_FEEDBACK_SENDING_BTN').value;
                break;
            case FormState.ERROR:
                buttonLabel = $translate('JSPNAV_CNT_SEND').value;
                break;
            default:
                break;
        }
        return {
            order: [
                'description'
            ],
            submitButton: {
                label: buttonLabel
            },
            fields: {
                description: {
                    title: $translate('WEB2_SELF_EXCLUSION_FEEDBACK_LABEL').value,
                    widget: FormControlType.MultilineTextInput,
                    options: {
                        rowsMax: 3
                    }
                }
            }
        };
    });
    const modalProperties = {
        isCloseAsBack: false,
        isAlert: true,
        isCentered: true,
        isFullHeightCentered: false,
        width: ModalWidth.SMALL,
        isFullHeight: false,
        topBarText: $translate('WEB2_SELF_EXCLUSION_FEEDBACK_TITLE').value
    };
    const feedBackProperties = computed(()=>({
            loaded: true,
            schema: feedbackSchema,
            uiSchema: uiSchema.value,
            isPending: isPending.value,
            hintLabel: $translate('WEB2_SELF_EXCLUSION_FEEDBACK_HINT').value
        }));
    async function onSubmit(data) {
        if (data.errors || data.customErrors) formState.value = FormState.INITIAL;
        else {
            formState.value = FormState.PENDING;
            try {
                await sendUserResponse({
                    body: data.formData.description.toString(),
                    channel: FeedbackChannel.SELF_EXCLUSION
                });
                emit('success');
            } catch (error) {
                formState.value = FormState.ERROR;
                throw error;
            }
        }
    }
    function closeModal() {
        emit('cancel');
    }
    return {
        modalSelector,
        modalProperties,
        feedBackProperties,
        formState,
        onSubmit,
        closeModal
    };
}
