const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    title: 'Feedback',
    required: [
        'description'
    ],
    properties: {
        description: {
            type: 'string',
            maxLength: 1000
        }
    }
};
export default schema;
