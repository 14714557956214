import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconSize } from '@leon-hub/icons';
import { PinCodeRequiredExceptionCode } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import { AnalyticsModalType, DialogAction, DialogComponent, PresetName } from 'web/src/modules/dialogs/enums';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useResponsibleGamblingStore } from 'web/src/modules/responsible-gambling/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { FormControlType } from 'web/src/components/Form/enums';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useDialogs } from 'web/src/modules/dialogs/composables';
export default function useResponsibleGamblingSelfExclusionRoutePage() {
    const siteConfigStore = useSiteConfigStore();
    const responsibleGamblingStore = useResponsibleGamblingStore();
    const I18nStore = useI18nStore();
    const { $translate } = useI18n();
    const { showDialog, closeDialog } = useDialogs();
    const router = useRouter();
    const showFeedbackModal = ref(false);
    const isAllowedToDoSelfExclusion = ref(true);
    const isButtonDisabled = ref(true);
    const id = ref(null);
    const selectedMonths = ref(6);
    const periodLabels = ref({});
    const periods = toRef(siteConfigStore, 'selfExclusionOptions');
    const selfExclusion = toRef(responsibleGamblingStore, 'selfExclusion');
    const locale = toRef(I18nStore, 'locale');
    const isLoading = false;
    const periodTranslated = [];
    const buttonProperties = computed(()=>({
            label: $translate('JS_ENABLE_OPTION').value,
            kind: ButtonKind.RED,
            fullWidth: true,
            disabled: isButtonDisabled.value
        }));
    const buttonPropertiesSupport = computed(()=>({
            label: $translate('WEB2_HELP').value,
            kind: ButtonKind.SECONDARY,
            rounded: true,
            fullWidth: true
        }));
    const periodEnums = computed(()=>periods.value.reduce((accumulator, period)=>{
            if (period.months) accumulator.push(String(period.months));
            return accumulator;
        }, []));
    const isSinglePeriod = computed(()=>1 === periodEnums.value.length);
    const schema = computed(()=>({
            $schema: 'http://json-schema.org/draft-07/schema#',
            $id: 'http://example.com/schemas/registration-leon-ru.json',
            type: 'object',
            properties: {
                selectPeriod: {
                    type: 'string',
                    enum: periodEnums.value
                }
            }
        }));
    const uiSchema = computed(()=>({
            submitButton: null,
            fields: {
                selectPeriod: {
                    disabled: !!isSinglePeriod.value || void 0,
                    widget: FormControlType.Select,
                    labels: periodLabels.value,
                    title: $translate('WEB2_SELF_EXCLUSION_PERIOD_PLACEHOLDER').value,
                    options: {
                        dropdownMinWidth: 280,
                        placeholder: $translate('WEB2_SELF_EXCLUSION_PERIOD_PLACEHOLDER').value
                    },
                    default: isSinglePeriod.value ? periodEnums.value[0] : ''
                }
            }
        }));
    const periodLabelParams = computed(()=>({
            count: String(selectedMonths.value)
        }));
    const periodLabel = computed(()=>LanguageMicroSettings.plural([
            $translate('WEB2_SELF_EXCLUSION_PERIOD_ONE', periodLabelParams).value,
            $translate('WEB2_SELF_EXCLUSION_PERIOD_TWO', periodLabelParams).value,
            $translate('WEB2_SELF_EXCLUSION_PERIOD_MANY', periodLabelParams).value
        ], selectedMonths.value, locale.value));
    const formattedFrom = computed(()=>{
        if (null !== selfExclusion.value) return selfExclusion.value.from ? DateTime.formatTimeStamp(selfExclusion.value.from, TimeFormats.dayMonthYearHoursMinutes) : '';
        return '';
    });
    const formattedUntil = computed(()=>{
        if (null !== selfExclusion.value) return selfExclusion.value.until ? DateTime.formatTimeStamp(selfExclusion.value.until, TimeFormats.dayMonthYearHoursMinutes) : '';
        return '';
    });
    const confirmMessageParams = computed(()=>({
            until: `${selectedMonths.value} ${periodLabel.value}`
        }));
    const confirmMessageSuccessParams = computed(()=>({
            from: `<span class="responsible-gambling__text--error">${formattedFrom.value}</span>`,
            until: `<span class="responsible-gambling__text--error">${formattedUntil.value}</span>`
        }));
    async function beforeMount() {
        for (const item of periods.value){
            const newItem = {
                ...item,
                btrKey: $translate(item.btrKey).value
            };
            periodTranslated.push(newItem);
        }
        periodLabels.value = periodTranslated.reduce((accumulator, period)=>{
            if (period.months && period.btrKey) accumulator[String(period.months)] = `${period.months} ${period.btrKey}`;
            return accumulator;
        }, {});
        await responsibleGamblingStore.loadSelfExclusion();
        const { selfExclusionId, selfExclusionMonths } = responsibleGamblingStore;
        if (selfExclusionId && selfExclusionMonths) {
            id.value = selfExclusionId;
            selectedMonths.value = selfExclusionMonths;
            responsibleGamblingStore.setSelfExclusionId(null);
            responsibleGamblingStore.setSelfExclusionMonths(null);
            responsibleGamblingStore.setSelfExclusionRouteBack(null);
            await processSelfExclusion(id.value, selectedMonths.value);
        }
        if (isSinglePeriod.value) isButtonDisabled.value = false;
    }
    function onChange(data) {
        selectedMonths.value = Number(data.formData.selectPeriod);
        isButtonDisabled.value = false;
    }
    function processSelfExclusion(Id, months) {
        responsibleGamblingStore.setSelfExclusionRouteBack(RouteName.RESPONSIBLE_GAMBLING_SELF_EXCLUSION);
        id.value = Id;
        return responsibleGamblingStore.doSelfExclusion(months).then(()=>{
            id.value = null;
            showSuccessModal();
            closeDialog(Id);
        }).catch((error)=>{
            if (error.message === PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED) {
                responsibleGamblingStore.setSelfExclusionId(id.value);
                responsibleGamblingStore.setSelfExclusionMonths(months);
                closeDialog(id.value);
                router.replace({
                    name: RouteName.PIN_CODE_PROFILE
                });
            }
        });
    }
    function showConfirmModal() {
        const { subscribe, id: innerId } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                width: ModalWidth.SMALL,
                analyticsModalType: AnalyticsModalType.WARNING,
                confirmMessage: $translate('WEB2_SELF_EXCLUSION_CONFIRM', confirmMessageParams).value,
                title: $translate('JS_SELF_EXCLUSION_CONFIRM_TITLE').value,
                iconSize: IconSize.SIZE_80,
                buttons: [
                    {
                        label: $translate('JSP_CCGATEWAY_BTN_CONFIRM').value,
                        kind: ButtonKind.RED
                    }
                ]
            }
        });
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                if (siteConfigStore.isResponsibleGamblingFeedbackEnabled) showFeedbackModal.value = true;
                else showCountdownModal();
            },
            [DialogAction.MODAL_CLOSE]: ()=>{
                closeDialog(innerId);
            }
        });
    }
    function showCountdownModal() {
        const buttonLKind = ButtonKind.BASE;
        const { id: innerId, subscribe } = showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                // 1 month restriction key: WEB2_FIN_DEPLIM_CONFIRM
                confirmMessage: $translate('WEB2_SELF_EXCLUSION_CONFIRM', confirmMessageParams).value,
                title: $translate('JS_SELF_EXCLUSION_CONFIRM_TITLE').value,
                modalComponent: DialogComponent.CountDownModal,
                iconSize: IconSize.SIZE_80,
                buttons: [
                    {
                        label: $translate('JSPACC_SETT_CANCEL').value,
                        kind: buttonLKind
                    }
                ]
            }
        });
        subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                closeDialog(innerId);
            },
            [DialogAction.MODAL_COUNTDOWN]: ()=>{
                processSelfExclusion(innerId, selectedMonths.value);
                isAllowedToDoSelfExclusion.value = false;
                return false;
            }
        });
    }
    function showSuccessModal() {
        const lButtonKind = ButtonKind.BASE;
        showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                confirmMessage: $translate('WEB2_SELF_EXCLUSION_MESSAGE', confirmMessageSuccessParams).value,
                title: $translate('WEB2_OPERATION_FINISHED').value,
                iconSize: IconSize.SIZE_80,
                buttons: [
                    {
                        kind: lButtonKind
                    }
                ]
            }
        });
    }
    function handleCloseFeedback() {
        showFeedbackModal.value = false;
    }
    function handleSuccessFeedback() {
        handleCloseFeedback();
        showCountdownModal();
    }
    function handleSupportClick() {
        router.push({
            name: RouteName.SUPPORT
        });
    }
    return {
        showFeedbackModal,
        handleCloseFeedback,
        handleSuccessFeedback,
        selfExclusion,
        handleSupportClick,
        formattedFrom,
        formattedUntil,
        buttonPropertiesSupport,
        schema,
        uiSchema,
        isLoading,
        onChange,
        buttonProperties,
        showConfirmModal,
        beforeMount
    };
}
