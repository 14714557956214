import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import openUrl from 'web/src/utils/url/openUrl';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import { useI18n } from 'web/src/modules/i18n/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingComprehensiveSelfExclusionRoutePage',
    setup (__props) {
        const { $translate } = useI18n();
        const buttonProperties = computed(()=>({
                label: $translate('WEB2_DOWNLOAD_FORM').value,
                kind: ButtonKind.SECONDARY,
                rounded: true
            }));
        function handleClick() {
            openUrl('http://www.gamingcommission.ca/docs/ScheduleK-InteractiveVoluntarySelf-ExclusionForm.pdf', '_blank');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['complex-restriction'])
            }, [
                (_openBlock(), _createBlock(VHintBlock, {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['responsible-gambling__hint-block'])
                }, {
                    label: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['responsible-gambling__hint-block-content'])
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['responsible-gambling--label-bottom-margin'])
                                }, _toDisplayString(_ctx.$t('WEB2_COMPLEX_EXCL_PART_FIRST')), 3),
                                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                                _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['responsible-gambling__important'])
                                }, _toDisplayString(_ctx.$t('WEB2_IMPORTANT_TITLE')), 3),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['responsible-gambling--label-bottom-margin'])
                                }, _toDisplayString(_ctx.$t('WEB2_COMPLEX_EXCL_PART_SECOND')), 3),
                                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_COMPLEX_EXCL_PART_THIRD')), 1)
                            ], 2)
                        ]),
                    footer: _withCtx(()=>[
                            _createVNode(VButton, _mergeProps(buttonProperties.value, {
                                class: _ctx.$style['responsible-gambling__support-button'],
                                onClick: handleClick
                            }), null, 16, [
                                "class"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingComprehensiveSelfExclusionRoutePage'
                ]
            ]);
        };
    }
});
